import { message } from 'antd';
import axios, { AxiosRequestConfig, Method } from 'axios';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const getToken = (): string | null => {
  return localStorage.getItem('accessToken');
};
// Base configuration for axios
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Replace with your API base URL
  timeout: 10000, // Request timeout
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

interface ApiRequestProps {
  url: string;
  method?: Method;
  payload?: any;
  showMessage?: boolean;
  Authentication?: boolean;
  naviToLogin?:boolean;
  cstmSuccMsg?:string;
  trackHistory?:boolean;
}
let navi = true;
let msgShow = true;

const useApiRequest = () => {
  const navigate = useNavigate();

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        // Clear the token from localStorage
        localStorage.removeItem('accessToken');
        // Redirect to the login page
        if(navi){
          navigate('/account');
        }

        // Show error message
        message.destroy();
        // message.error('Session expired. Please login again.');
        if(msgShow){
          message.warning('Please login')
        }
      }
      return Promise.reject(error);
    }
  );

  const apiRequest = async ({
    url,
    method = 'GET',
    payload = {},
    showMessage = false,
    Authentication = false,
    naviToLogin = true,
    cstmSuccMsg,
    trackHistory=false,
  }: ApiRequestProps): Promise<any> => {
    navi = naviToLogin;
    msgShow = showMessage;
    if (Authentication &&!getToken()) {
      // Redirect to login page if token is not found

      // console.log(window.URL)
      if(naviToLogin){
        navigate('/account');
      }
      if(showMessage){
        message.error("User not authenticated. Please login.");
      }
    }
    

    // const token = getToken();
    // if (!token) {
    //   // Redirect to login page if token is not found
    //   navigate('/account');
    //   message.error("User not authenticated. Please login.");
    // }

    try {
      const config: AxiosRequestConfig = {
        method,
        url,
        ...(method === 'POST' || method === 'PUT' ? { data: payload } : { params: payload }),
      };

      const response = await axiosInstance(config);
      if (showMessage) {
        message.success(cstmSuccMsg?cstmSuccMsg:"Request Success!");
      }
      
      return response;
    } catch (error: any) {
      if (showMessage) {
        message.error(`Error: ${error.response ? error.response.data.message : error.message}`);
      }
      console.error('API request error:', error);
      throw error;
    }
  };

  return { apiRequest };
};

export default useApiRequest;


export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}



// Room types 

export const serviceOptions = [
  { value: "", label: "Select one..." },
  // { value: "Bulk ordering", label: "Bulk ordering" },
  { value: "Virtual Staging", label: "Virtual Staging" },
  { value: "Commercial Virtual Staging", label: "Commercial Virtual Staging" },
  // { value: "Matterport Virtual Staging", label: "Matterport Virtual Staging" },
  { value: "Virtual Renovation", label: "Virtual Renovation" },
  { value: "Image Enhancement", label: "Image Enhancement" },
  { value: "Object Removal", label: "Object Removal" },
  { value: "Occupied To Vacant", label: "Occupied To Vacant" },
  { value: "Day To Dusk", label: "Day To Dusk" },
  { value: "Floor Plan", label: "Floor Plan" },
  // { value: "360 Virtual Tour", label: "360° Virtual Tours" },
  // { value: "3D rendering", label: "3D Render" },
  // { value: "Single Property Website", label: "Single Property Website" },
  { value: "Other", label: "Other" }
];

export const roomOptions = [
    "Living/Dining/Kitchen",
    "Living/Dining",
    "Family Room",
    "Dining/Kitchen",
    "Dining",
    "Kitchen",
    "Bedroom 1",
    "Bedroom 2",
    "Bedroom 3",
    "Bedroom 4",
    "Primary/Master Bedroom",
    "Study/Office",
    "Closet",
    "Outdoor Living",
    "Sunroom",
    "Outdoor Living/Dining",
    "Bathroom/Ensuite",
    "Rumpus Room",
    "Game Room",
    "Media Room/Theatre Room",
    "Single Room Studio",
    "Nursery Room",
    "Kids Room/Play Room",
    "Yoga/Meditation Room",
    "Gym/Workout Room",
    "Lounge",
    "Other",
    "Living",
    "Dining",
    "Kitchen",
    "Lounge/Dining/Kitchen",
    "Lounge/Kitchen",
    "Lounge/Dining",
    "Dining/Kitchen",
    "Lounge",
    "Family Room",
    "Primary/Master Bedroom",
    "Bedroom",
    "Study",
    "Home Office",
    "Sun Room",
    "Outdoor Living",
    "Bathroom/Ensuite",
    "Rumpus Room",
    "Games Area",
    "Media Room",
    "Kids Room",
    "Nursery Room",
    "Single Room Studio",
    "Others (Please specify in additional information below)",
  ];

  export  const designCardsData = [
    { 
        name: "Modern",
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/main_photo_4-_1727560488.jpg`,
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_1_1733917059.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_2_1733917083.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_4_1733917122.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_5_1733917137.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_3_1733917106.jpg`
        ]
    },
    { 
        name: "Contemporary",
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-curtis-adams-1694007-15242103_lr-g_1727628002.jpg`,
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_1_1733916307.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_2_1733916349.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_3_1733916377.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_4_1733916400.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_5_1733916427.jpg`
        ]
    },
    { 
        name: "Traditional",
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Living1_1727635060.jpg`,
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/traditional_1_1733917394.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/traditional_2_1733917447.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/traditional_3_1733917465.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/traditional_4_1733917485.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/traditional_5_1733917549.jpg`
        ]
    },
    { 
        name: "Boho",
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/main_photo_4-_1727560488.jpg`,
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/boho_1_1733930829.png`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/boho_1_1733930829.png`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/boho_1_1733930829.png`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/boho_1_1733930829.png`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/boho_1_1733930829.png`
        ]
    },
    { 
        name: "Scandinavian",
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/living_dining_1727637249.jpg`,
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Scandinavian_1_1733917222.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Scandinavian_2_1733917254.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Scandinavian_3_1733917279.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Scandinavian_4_1733917306.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Scandinavian_5_1733917326.jpg`,
        ]
    },
    { 
        name: "Farmhouse", 
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/LIVING-1Bg_1727635313.jpg`, 
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/farmhouse_1_1733916527.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/farmhouse_2_1733916552.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/farmhouse_3_1733916571.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/farmhouse_4_1733916593.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/farmhouse_5_1733916612.jpg`,
        ] 
    },
    { 
        name: "Industrial", 
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/bedroom-01_indus_1727635720.jpg`, 
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_1_1733916848.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_2_1733916872.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_3_1733916904.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_4_1733916922.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_5_1733916953.jpg`
        ] 
    },
    { 
        name: "Glam", 
        thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/livng_dining_1727635915.jpg`, 
        images: [
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/glam_1_1733916703.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/glam_2_1733916721.jpg`, 
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/glam_3_1733916743.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/glam_4_1733916760.jpg`,
            `${process.env.REACT_APP_ADMIN_STORAGE_URL}/glam_5_1733916776.jpg`
        ] 
    },
];

export const threeDesignCard = [
  { 
      name: "Modern",
      thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/main_photo_4-_1727560488.jpg`,
      images: [
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_1_1733917059.jpg`,
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_2_1733917083.jpg`,
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_4_1733917122.jpg`,
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_5_1733917137.jpg`,
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/modern_3_1733917106.jpg`
      ]
  },
  { 
      name: "Contemporary",
      thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/pexels-curtis-adams-1694007-15242103_lr-g_1727628002.jpg`,
      images: [
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_1_1733916307.jpg`,
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_2_1733916349.jpg`,
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_3_1733916377.jpg`,
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_4_1733916400.jpg`,
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/Contemporary_5_1733916427.jpg`
      ]
  },
  { 
      name: "Industrial", 
      thumbnail: `${process.env.REACT_APP_ADMIN_STORAGE_URL}/bedroom-01_indus_1727635720.jpg`, 
      images: [
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_1_1733916848.jpg`, 
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_2_1733916872.jpg`, 
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_3_1733916904.jpg`, 
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_4_1733916922.jpg`, 
          `${process.env.REACT_APP_ADMIN_STORAGE_URL}/industrial_5_1733916953.jpg`
      ]
  },
];

