import { Link } from "react-router-dom";

const HowItWorks = (props: any) => {
  return (
    <section
      className="how-it-works radius-60-bg-grey mt-5"
      data-aos="fade-up"
      style={props?.style}
    >
      <div>
        <div className="text-center" data-aos="fade-up">
          <h2 className="d-inline-block">
            Embrace the simplicity of how it works with 3 Easy Steps
          </h2>
          {/* <p>A Three Process That Takes 12 to 24 Hours</p> */}
        </div>
        <div className="steps-row pb-4 pt-5">
          <div className="row justify-content-center">
            <div
              className="col-md-3 col-sm-6 col-6 text-center"
              data-aos="fade-up"
            >
              {props.stepOne ? (
                <>
                  <img
                    src={props.stepOne.img}
                    height={87}
                    width={87}
                    alt="Step-1"
                  />
                  <p className="mt-4 weight-6">1. {props.stepOne.text}</p>
                </>
              ) : (
                <>
                  <img
                    src="/assets/images/upload-icon.svg"
                    height={87}
                    width={87}
                    alt="Step-1"
                  />
                  <p className="mt-4 weight-6">1. Upload Your Photos</p>
                </>
              )}
            </div>
            <div
              className="col-md-3 col-sm-6 col-6 text-center"
              data-aos="fade-up"
            >
              {props.stepTwo ? (
                <>
                  <img
                    src={props.stepTwo.img}
                    height={87}
                    width={87}
                    alt="Step-1"
                  />
                  <p className="mt-4 weight-6">2. {props.stepTwo.text}</p>
                </>
              ) : (
                <>
                  <img
                    src="/assets/images/submit-icon.svg"
                    height={87}
                    width={87}
                    alt="Step-1"
                  />
                  <p className="mt-4 weight-6">2. Submit Your Order</p>
                </>
              )}
            </div>
            <div
              className="col-md-3 col-sm-6 col-6 text-center"
              data-aos="fade-up"
            >
                {props.stepThree?(
                    <>
                        <img
                src={props.stepThree.img}
                height={87}
                width={87}
                alt="Step-2"
              />
              <p className="mt-4 weight-6">3. {props.stepThree.text}</p>
                    </>
                ):(<>
    <img
                src="/assets/images/desired-icon.svg"
                height={87}
                width={87}
                alt="Step-2"
              />
              <p className="mt-4 weight-6">3. Get Your Desired Photos</p>
                </>)}
          
            </div>
          </div>
        </div>
        <div className="text-center">
          <a
            href="/order-bulk-quotes/#bulkQuoteForm"
            style={{ background: "var(--theme-deep-green)" }}
            className="theme-btn long-btn"
            onClick={(e)=>{
              e.preventDefault()
              window.scrollTo(0,1100)
            }}
          >
            Get Started
          </a>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
