import { Footer } from "antd/es/layout/layout";
import "bootstrap/dist/css/bootstrap.css";
import "./header-footer.css";
import { Button, Col, Form, Input, message, Row } from "antd";
import { Link } from "react-router-dom";
import ClientRatingSlider from "../Sections/ClientRatingSlider";
import FlipReviewGrid from "../Sections/FlipReviewGrid";
import CompareTable from "../Sections/CompareTable";
import ClientMarquee from "../Sections/TrustedClientMarquee";
import AnimatedNumbers from "react-animated-numbers";
import onlyLogo from "../../logo/white-only-logo.png";
import writtenLogo from "../../logo/WHITE_NAME.png";
import React, { useEffect } from "react";
import { useForm } from "antd/es/form/Form";
import useApiRequest from "../../utils/helperFunctions";

export const OnlyFooter = () => {
  return (
    <>
      <footer className="footer-section">
        <div className="hp-footer-container">
          <div className="footer-content pt-lg-5 pb-lg-2">
            <div className="footer-links-container">
              <div className="footer-row">
                <div className="footer-col">
                  <div className="footer-widget">
                    <div className="footer-logo-box">
                      <div className="footer-inner-section footer-logo">
                        <Link to="/">
                          <img
                            src={onlyLogo}
                            className="img-fluid only-logo"
                            alt="logo"
                          />
                          <img
                            src={writtenLogo}
                            className="img-fluid written"
                            alt="logo"
                          />
                        </Link>
                      </div>
                      <div className="footer-inner-section footer-menu">
                        <div className="footer-menu-heading">
                          <h3 className="text">Contact US</h3>
                        </div>
                        <div className="footer-menu-text">
                          <p>Stagobox, Inc</p>
                          <p>Unit 101, Oxford Towers</p>
                          <p>HAL Kodhihalli</p>
                          <p>Banglore, India - 560008</p>
                          <p>&nbsp;</p>
                          <a href="mailto:contact@stagobox.com">
                            contact@stagobox.com
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-col flex-grow-1">
                  <div className="footer-service-menus">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4">
                        <div className="footer-inner-section">
                          <div className="footer-menu">
                            <div className="footer-menu-heading">
                              <h3 className="text">Virtual Staging</h3>
                            </div>
                            <div className="footer-menu-menu">
                              <ul>
                                <li>
                                  <Link
                                    to="/services/residential/virtual-staging"
                                    className="footer-menu-link"
                                  >
                                    Residential Virtual Staging
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/commercial/virtual-staging"
                                    className="footer-menu-link"
                                  >
                                    Commercial Virtual Staging
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="footer-menu-heading">
                              <h3 className="text">Residential Renovation</h3>
                            </div>
                            <div className="footer-menu-menu">
                              <ul>
                                <li>
                                  <a
                                    href="/services/residential/virtual-renovation#change-wall-color"
                                    className="footer-menu-link"
                                  >
                                    Change Wall Color
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/residential/virtual-renovation/#add-change-floor"
                                    className="footer-menu-link"
                                  >
                                    Add/Change Floor
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/residential/virtual-renovation/#add-change-ceiling"
                                    className="footer-menu-link"
                                  >
                                    Add/Change Ceiling
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/residential/virtual-renovation/#bathroom-remodel"
                                    className="footer-menu-link"
                                  >
                                    Bathroom Remodel
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/residential/virtual-renovation/#add-fixture"
                                    className="footer-menu-link"
                                  >
                                    Add Fixture
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/residential/virtual-renovation/#kitchen-remodel"
                                    className="footer-menu-link"
                                  >
                                    Kitchen Remodel
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/residential/virtual-renovation/#add-pool"
                                    className="footer-menu-link"
                                  >
                                    Add Pool
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/residential/virtual-renovation/#add-deck"
                                    className="footer-menu-link"
                                  >
                                    Add Deck
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/residential/virtual-renovation/#virtual-landscaping"
                                    className="footer-menu-link"
                                  >
                                    Virtual Landscaping
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4">
                        <div className="footer-inner-section">
                          <div className="footer-menu">
                            <div className="footer-menu-heading">
                              <h3 className="text">Commercial Renovation</h3>
                            </div>
                            <div className="footer-menu-menu">
                              <ul>
                                <li>
                                  <a
                                    href="/services/commercial/commercial-renovation/#change-wall-color"
                                    className="footer-menu-link"
                                  >
                                    Change Wall Color
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/commercial/commercial-renovation/#add-change-floor"
                                    className="footer-menu-link"
                                  >
                                    Add/Change Floor
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/commercial/commercial-renovation/#add-change-ceiling"
                                    className="footer-menu-link"
                                  >
                                    Add/Change Ceiling
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/commercial/commercial-renovation/#add-fixture"
                                    className="footer-menu-link"
                                  >
                                    Add Fixture
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/commercial/commercial-renovation/#virtual-landscaping"
                                    className="footer-menu-link"
                                  >
                                    Virtual Landscaping
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="footer-inner-section">
                          <div className="footer-menu">
                            <div className="footer-menu-heading">
                              <h3 className="text">
                                Residential Photo Editing
                              </h3>
                            </div>
                            <div className="footer-menu-menu">
                              <ul>
                                <li>
                                  <Link
                                    to="/services/residential/residential-image-enhancement"
                                    className="footer-menu-link"
                                  >
                                    Image Enhancement
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/residential/occupied-to-vacant"
                                    className="footer-menu-link"
                                  >
                                    Occupied to Vacant
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/residential/day-to-dusk"
                                    className="footer-menu-link"
                                  >
                                    Day to Dusk
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/residential/object-removal"
                                    className="footer-menu-link"
                                  >
                                    Object Removal
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-4">
                        <div className="footer-inner-section">
                          <div className="footer-menu">
                            <div className="footer-menu-heading">
                              <h3 className="text">Commercial Photo Editing</h3>
                            </div>
                            <div className="footer-menu-menu">
                              <ul>
                                <li>
                                  <Link
                                    to="/services/commercial/image-enhancement"
                                    className="footer-menu-link"
                                  >
                                    Image Enhancement
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/commercial/occupied-to-vacant"
                                    className="footer-menu-link"
                                  >
                                    Occupied to Vacant
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/commercial/day-to-dusk"
                                    className="footer-menu-link"
                                  >
                                    Day to Dusk
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="/services/commercial/object-removal"
                                    className="footer-menu-link"
                                  >
                                    Object Removal
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="footer-inner-section">
                          <div className="footer-menu">
                            <div className="footer-menu-heading">
                              <h3 className="text">Floor Plan</h3>
                            </div>
                            <div className="footer-menu-menu">
                              <ul>
                                <li>
                                  <a
                                    href="/services/residential/floor-plan/#td-floor-plan-wf"
                                    className="footer-menu-link"
                                  >
                                    2d Floor Plan (with furniture)
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/residential/floor-plan/#td-floor-plan-wof"
                                    className="footer-menu-link"
                                  >
                                    2d Floor Plan (without furniture)
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="footer-inner-section">
                          <div className="footer-menu">
                            <div className="footer-menu-heading">
                              <h3 className="text">Graphic Design</h3>
                            </div>
                            <div className="footer-menu-menu">
                              <ul>
                                <li>
                                  <a
                                    href="/services/graphic-designing/#business-card-design"
                                    className="footer-menu-link"
                                  >
                                    Business Card Design
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/graphic-designing/#logo-and-branding"
                                    className="footer-menu-link"
                                  >
                                    Logo & Branding
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/graphic-designing/#vector-design"
                                    className="footer-menu-link"
                                  >
                                    Vector Design
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="/services/graphic-designing/#digital-display-design"
                                    className="footer-menu-link"
                                  >
                                    Digital Display Design
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer-col">
                  <div className="footer-main-menus footer-logo-box">
                    <div className="footer-inner-section">
                      <div className="footer-menu">
                        <div className="footer-menu-heading">
                          <Link
                            to="/about-us"
                            className="footer-menu-link text"
                          >
                            About us
                          </Link>
                        </div>
                        <div className="footer-menu-heading">
                          <Link
                            to="/contact-us"
                            className="footer-menu-link text"
                          >
                            Contact us
                          </Link>
                        </div>
                        <div className="footer-menu-heading">
                          <Link to="/blogs" className="footer-menu-link text">
                            Blog
                          </Link>
                        </div>
                        <div className="footer-menu-heading">
                          <Link to="/gallery" className="footer-menu-link text">
                            Gallery
                          </Link>
                        </div>
                        <div className="footer-menu-heading">
                          <Link to="" className="footer-menu-link text">
                            Resources
                          </Link>
                        </div>
                        <div className="footer-menu-heading">
                          <Link to="" className="footer-menu-link text">
                            Services
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="footer-inner-section">
                      <div className="footer-menu">
                        <div className="footer-menu-heading">
                          <h3 className="text">Terms & Policy</h3>
                        </div>
                        <div className="footer-menu-menu">
                          <ul>
                            <li>
                              <Link
                                to="/terms-and-conditions"
                                className="footer-menu-link"
                              >
                                Terms
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="/privacy-policy"
                                className="footer-menu-link"
                              >
                                Privacy Policy
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="footer-social-icon">
              <div className="footer-social-link">
                <a
                  href="https://www.facebook.com/profile.php?id=61559641502123&mibextid=LQQJ4d"
                  target="_blank"
                >
                  <img
                    src="/assets/images/facebook.png"
                    width="30px"
                    alt="Facebook"
                  />
                </a>
                <a
                  href="https://www.instagram.com/stagobox_staging?igsh=MXV2NDlmdm5tcTgz"
                  target="_blank"
                >
                  <img
                    src="/assets/images/instagram.png"
                    width="30px"
                    alt="instagram"
                  />
                </a>
                <a
                  href="https://x.com/stagobox?s=11&t=jkTv4SRvX1q3qLRRfJ2SUw"
                  target="_blank"
                >
                  <img
                    src="/assets/images/twitter.png"
                    width="30px"
                    alt="twitter"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/stagobox/"
                  target="_blank"
                >
                  <img
                    src="/assets/images/linkedin.png"
                    width="30px"
                    alt="linkedin"
                  />
                </a>
              </div>
              <div className="copyright">
                <div className="copyright-text text-left">
                  <p> &copy; {new Date().getFullYear()} Stagobox, Inc | All Right Reserved</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export const FooterMapSection = () => {
  const [num, setNum] = React.useState(78);
  const [num2, setNum2] = React.useState(19);
  const [num3, setNum3] = React.useState(50);

  useEffect(() => {
    setNum(78);
    setNum2(19);
    setNum3(50);
  }, [window.location.pathname]);

  return (
    <div className="footer_map-section">
      <div className="map_box">
        <div className="row">
          <div className="col-md-4">
            <div className="map-card">
              <div className="span-number">
                <AnimatedNumbers
                  includeComma
                  // className={styles.container}
                  transitions={(index) => ({
                    type: "spring",
                    duration: 2,
                  })}
                  animateToNumber={num}
                  fontStyle={{
                    fontSize: 56,
                    // color: "#32AE96",
                  }}
                />
                %
              </div>
              <span>FASTER</span>
              <p>Our virtually staged homes sell 78% faster on average</p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="map-card">
              <div className="span-number">
                <AnimatedNumbers
                  includeComma
                  // className={styles.container}
                  transitions={(index) => ({
                    type: "spring",
                    duration: 2,
                  })}
                  animateToNumber={num2}
                  fontStyle={{
                    fontSize: 56,
                    // color: "#32AE96",
                  }}
                />
                %
              </div>
              <span>HIGHER</span>
              <p>
                Our virtually staged homes sell for 19% higher amount on average
              </p>
            </div>
          </div>

          <div className="col-md-4">
            <div className="map-card">
              <div className="span-number">
                <AnimatedNumbers
                  includeComma
                  // className={styles.container}
                  transitions={(index) => ({
                    type: "spring",
                    duration: 2,
                  })}
                  animateToNumber={num3}
                  fontStyle={{
                    fontSize: 56,
                    // color: "#32AE96",
                  }}
                />
                k+
              </div>
              <span>HOMES</span>
              <p>We have virtually staged over 50k homes successfully</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FooterSubscribe = () => {
  const [form] = Form.useForm();
  const { apiRequest } = useApiRequest();

  const handleFinish = async (values: any) => {
    try {
      const resp = await apiRequest({
        url: "subscribe",
        method: "POST",
        showMessage: false,
        payload: values,
      });
      if (resp.status === 201) {
        message.success("Subscribed successfully");
        form.resetFields();
      } else {
        message.error("Failed to subscribe, Try again Later");
      }
    } catch (errors) {
      console.error("Failed to fetch service list", errors);
    }
  };

  return (
    <div style={{ background: "rgb(253 245 224)" }}>
      <div className="footer-widget subscribe-detail">
        <div className="footer-widget-heading">
          <h3>Subscribe</h3>
          <p className="text-center" style={{ fontSize: "16px" }}>
            Don't miss to subscribe to new feeds <br />
            Kindly fill the form below.
          </p>
        </div>
        <div className="subscribe-form">
          <Form
            form={form}
            onFinish={handleFinish}
            layout="vertical"
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your email address!",
                },
                {
                  type: "email",
                  message: "Please enter a valid email address!",
                },
              ]}
            >
              <Input
                placeholder="Enter Email Address"
                style={{ borderRadius: "0px" }}
              />
            </Form.Item>
            {/* <Form.Item> */}
            <button
              // type="primary"
              type="submit"
              className="theme-btn"
              style={{ borderRadius: "6px" }}
            >
              Subscribe Now
            </button>
            {/* </Form.Item> */}
          </Form>
        </div>
      </div>
    </div>
  );
};

const MainFooter = () => {
  return (
    <>
      <div className="pt-5 pb-5 mb-md-5 mt-md-5">
        <ClientRatingSlider />
      </div>

      <div>
        <FlipReviewGrid />
      </div>

      <div className="hp-container">
        <CompareTable />
      </div>

      <div>
        <ClientMarquee />
      </div>

      <Footer style={{ padding: "0px" }}>
        <FooterMapSection />

        <FooterSubscribe />

        <OnlyFooter />
      </Footer>
    </>
  );
};

export default MainFooter;
