import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.addEventListener('error', function(event) {
  const chunkFailedMessage = /Loading chunk \d+ failed/;
  if (chunkFailedMessage.test(event.message)) {
    window.location.reload();
  }
});

root.render(
  window.location.origin === "http://localhost:3000" ?
    <App /> : 
  <React.StrictMode>
  <App />
</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
