import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Checkbox,
  Card,
  Space,
  message,
  Row,
  Col,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import useApiRequest from "../../utils/helperFunctions";
import PhoneInput from "react-phone-input-2";

interface ImageData {
  url: string;
  additional_services: any[];
  room_area: string;
  selected_room_items: string[];
  additional_room_items: string;
  amount: string;
  like_in: string[];
  story_level: string;
  template_orientation: string;
  virtual_staging_text: string;
}

interface OrderData {
  order_id: string;
  invoice_no: string;
  user_email: string;
  order_status: string;
  final_amount: string;
  images: ImageData[];
  order_name: string;
  additional_services: any[];
  reciever_details: any;
}

const EditOrderForm: React.FC = () => {
  const [form] = Form.useForm();
  const [orderData, setOrderData] = useState<OrderData | null>(null);
  const { apiRequest } = useApiRequest();
  const [orderId, setOrderId] = useState<string>("");
  const [duskEffect, setDuskEffect] = useState<any>([]);
  const [selectedDayToDusk, setSelectedDayToDusk] = useState<any>({
    effects: [],
    sky_replacement: "",
  });
  const [likeIN, setLikeIN] = useState<any>([]);
  const [isSkyEffect, setIsSkyEffect] = useState<boolean>(false);

  const items = [
    {
      id: 1,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Let our Experts decide",
    },
    {
      id: 2,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Bold hues without clouds",
    },
    {
      id: 3,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Bold Sky with clouds",
    },
    {
      id: 4,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Subtle hues with clouds",
    },
    {
      id: 5,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Calm blue with a hint of color",
    },
    {
      id: 6,
      src: "https://www.signfix.com.au/wp-content/uploads/2017/09/placeholder-600x400.png",
      name: "Subtle hues",
    },
  ];

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const key = queryParams.get("oid");
    if (key) setOrderId(key);
  }, []);

  useEffect(() => {
    if (orderId) fetchOrderDetails();
  }, [orderId]);

  const fetchOrderDetails = async () => {
    try {
      const resp = await apiRequest({
        url: `order-details?order_id=${orderId}`,
        method: "GET",
        showMessage: false,
        Authentication: true,
      });
      if (resp?.data) {
        setOrderData(resp.data);
        console.log("resp?.data", resp?.data);

        const userInfo = {
          email: resp?.data?.reciever_details.email,
          name: resp?.data?.reciever_details.name,
          phone: resp?.data?.reciever_details.phone,
        };

        const formData = {
          ...userInfo,
          ...resp?.data,
        };

        form.setFieldsValue(formData);
      }
    } catch (error) {
      console.error("Failed to fetch order details", error);
    }
  };

  const handleFormSubmit = async (values: OrderData) => {
    try {
      const payload = { ...orderData, ...values };
      await apiRequest({
        url: "update-order",
        method: "POST",
        payload,
        Authentication: true,
      });
      message.success("Order updated successfully");
    } catch (error) {
      message.error("Failed to update order");
    }
  };

  const handleUpload = (info: any) => {
    if (info.file.status === "done") {
      const newImage: ImageData = {
        url: URL.createObjectURL(info.file.originFileObj),
        additional_services: [],
        room_area: "",
        selected_room_items: [],
        additional_room_items: "",
        amount: "",
        like_in: [],
        story_level: "",
        template_orientation: "",
        virtual_staging_text: "",
      };

      setOrderData(
        orderData
          ? { ...orderData, images: [...orderData.images, newImage] }
          : null
      );
    }
  };

  console.log({ orderData });

  const fetchEffects = async (value: string) => {
    try {
      const response = await apiRequest({
        url: `/get-name-types/${value}`,
        method: "GET",
        showMessage: false,
      });

      console.log("response ==>> ", response);
      if (value === "?name=floorplan") {
        setLikeIN(response?.data);
      } else {
        setDuskEffect(response?.data);
      }
    } catch (error) {
      console.error("Error fetching room options:", error);
    }
  };

  useEffect(() => {
    if (orderData?.order_name === "/services/floor-plan-form") {
      fetchEffects("?name=floorplan");
    } else if (orderData?.order_name === "Day to Dusk") {
      fetchEffects("?name=Day_to_Dusk");
    }
  }, []);

  const handleDuskChange = (id: number, isChecked: boolean) => {
    if (id === 13) {
      setIsSkyEffect(isChecked);
    }
    setSelectedDayToDusk((prev: any) => ({
      ...prev,
      effects: isChecked
        ? [...prev.effects, id]
        : prev.effects.filter((item: number) => item !== id),
    }));
  };

  if (!orderData) return <div>Loading...</div>;

  return (
    <Card
      title={`Edit Order ${
        " - " + orderData?.order_name || orderData?.user_email
      }`}
      style={{ maxWidth: "100%", margin: "auto" }}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormSubmit}
        initialValues={orderData}
      >
        <Row gutter={10}>
          <Col xs={24} md={12}>
            <Form.Item
              label="Your Name"
              name="name"
              rules={[{ required: true, message: "Please enter your name" }]}
            >
              <Input placeholder="For example; John Smith" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email",
                },
              ]}
            >
              <Input placeholder="For example; johnsmith98@gmail.com" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item
              label="Phone Number"
              name="phone"
              rules={[
                { required: true, message: "Please enter your phone number" },
              ]}
            >
              <PhoneInput country={"in"} inputStyle={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <h3>Images</h3>
        {orderData.images.map((image, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              padding: 20,
              border: "1px solid #ccc",
              borderRadius: 8,
              maxWidth: 900,
              width: "100%",
              margin: "10px 0",
              boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                flex: "1 1 0%",
                marginRight: 20,
                maxWidth: 350,
                width: "100%",
              }}
            >
              <img
                src={image.url}
                alt={`Image ${index}`}
                style={{ height: "auto", width: "100%", borderRadius: 8 }}
              />
            </div>
            <div>
              <h3 style={{ fontSize: 14 }}>
                Would you like any of the below effects?
              </h3>
              <div>
                {["Add fire to fireplace", "TV screen replacement"].map(
                  (effect) => (
                    <label
                      key={effect}
                      style={{ display: "block", marginBottom: 5 }}
                    >
                      <Checkbox
                        defaultChecked={image.like_in.includes(effect)}
                      />{" "}
                      {effect}
                    </label>
                  )
                )}

                <div className="dusk_checkbox">
                  {duskEffect?.map((item: any) => (
                    <Checkbox
                      key={item.id}
                      onChange={(e) =>
                        handleDuskChange(item.id, e.target.checked)
                      }
                      checked={selectedDayToDusk?.effects.includes(item.id)}
                    >
                      {item.name_types}
                    </Checkbox>
                  ))}
                </div>
                {isSkyEffect && (
                    <ul className="sky-checkbox-list">
                      <h3 className="mt-4" style={{ fontSize: "14px" }}>
                        Select sky effect
                      </h3>
                      {items?.map((item) => (
                        <li key={item.id}>
                          <input
                            type="checkbox"
                            // id={`myCheckbox${item.id}_${cardNumber}`}
                            // checked={skyID?.includes(
                            //   item.id + "_" + cardNumber
                            // )} // Check if the uid exists
                            // onChange={(e) => {
                            //   const uid = `${item.id}_${cardNumber}`;
                            //   handleCheckboxChange(
                            //     item.id,
                            //     item.name,
                            //     uid,
                            //     e.target.checked
                            //   );
                            // }}
                          />
                          <label htmlFor={`myCheckbox${item.id}_`}>
                            <img src={item.src} alt={item.name} />
                            <span>{item.name}</span>
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
              </div>
              <div style={{ fontSize: 16, marginBottom: 10 }}>
                Additional Information (Optional)
              </div>
              <textarea
                placeholder="Have something on your mind? Let us know..."
                style={{ width: "100%" }}
                defaultValue={image.additional_room_items}
              ></textarea>
            </div>
          </div>
        ))}

        <Upload customRequest={handleUpload} showUploadList={false}>
          {" "}
          <Button icon={<UploadOutlined />}>Upload Image</Button>{" "}
        </Upload>

        <Form.Item>
          {" "}
          <Button type="primary" htmlType="submit">
            Submit
          </Button>{" "}
        </Form.Item>
      </Form>
    </Card>
  );
};

export default EditOrderForm;
